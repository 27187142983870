import React from 'react';
import cx from 'clsx';
import Text from '../Text/Text';
import classes from './TextWithImage.module.scss';

export interface TextWithImageProps extends React.HTMLProps<HTMLDivElement> {
  image?: string,
  imageAlt?: string,
  description?: string,
  title?: string,
}

export default function TextWithImage({
  className,
  image,
  imageAlt,
  description,
  title,
  ...others
}: TextWithImageProps) {
  return (
    <div className={cx(classes.element, className)} {...others}>
      {image && (
        <div className={classes.imageContainer}>
          <img className={classes.image} src={image} alt={imageAlt || ''} />
        </div>
      )}
      {title && <Text component="h3" className={classes.elementTitle}>{title}</Text>}
      {description && <Text className={classes.elementDescription}>{description}</Text>}
    </div>
  );
}
