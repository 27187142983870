export default function checkFilled(answers?: (string | number | boolean)[]) {
  if (!answers) return false;
  return answers.find(answer => {
    switch (typeof answer) {
      case 'string':
        return answer.length > 0;
      case 'number':
        return answer > 0;
      case 'boolean':
        return answer;
      default:
        return false;
    }
  });
}
