import { useState } from 'react';
import { useRouter } from 'next/router';
import cleanSurveyCookies from '../util/cleanSurveyCookies';

export default function useCleanCookies(reload?: boolean) {
  const router = useRouter();
  const [started, setStarted] = useState(false);

  const checkCookies = () => (
    document && (document.cookie.includes('userToken') || document.cookie.includes('accessToken') || document.cookie.includes('sfUser'))
  );

  const reloadPage = async () => {
    setTimeout(() => {
      if (checkCookies()) {
        cleanSurveyCookies();
        router.reload();
      }
    }, 300000);
  };

  const cleanCookies = () => {
    if (reload) {
      if (!started) {
        setStarted(true);
        reloadPage();
      }
    } else {
      if (checkCookies()) {
        cleanSurveyCookies();
      }
    }
  };

  return cleanCookies;
}
