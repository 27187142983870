import { ResultProps } from '../../Result/Result';

type ResultsProp = {
  negativeResult?: ResultProps,
  neutralPoint?: number,
  middleResult?: ResultProps,
  positivePoint?: number,
  positiveResult: ResultProps,
  resultPoint: number,
}

export default function getResultScreen({
  negativeResult,
  neutralPoint,
  middleResult,
  positivePoint,
  positiveResult,
  resultPoint,
}: ResultsProp) {
  let resultScreen = negativeResult || positiveResult;

  if (neutralPoint) {
    if (resultPoint >= neutralPoint && middleResult) {
      resultScreen = middleResult;
    }
  }
  if (positivePoint) {
    if (resultPoint >= positivePoint && positiveResult) {
      resultScreen = positiveResult;
    }
  }
  return resultScreen;
}
