import React, { useEffect, useState } from 'react';
import cx from 'clsx';
import classes from './ButtonsContainer.module.scss';

export interface ButtonsContainerProps<T> extends React.HTMLProps<T> {
  className?: string;
  component?: React.ComponentType<T> | React.ElementType,
  align?: 'left' | 'center' | 'right';
  direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
}

export default function ButtonsContainer<T = 'div'>({
  className,
  component,
  align = 'left',
  direction = 'row',
  style = {},
  ...others
}: ButtonsContainerProps<T>) {
  const Element = component || 'div';

  const getAlign = () => {
    const flexAlign = align === 'left' ? 'flex-start' : align === 'right' ? 'flex-end' : 'center';
    if (direction?.includes('row')) {
      return { justifyContent: flexAlign };
    } else {
      return { alignItems: flexAlign };
    }
  };

  const [alignStyle, setAlignStyle] = useState(getAlign());

  useEffect(() => {
    setAlignStyle(getAlign);
  }, [align]);

  return (
    <Element
      className={cx(classes.wrapper, className)}
      style={{
        ...style,
        textAlign: align,
        flexDirection: direction,
        ...alignStyle,
      }}
      {...others}
    />
  );
}
