import React from 'react';
import cx from 'clsx';
import Text from '../Text/Text';
import Field, { FieldProps } from '../Field/Field';
import Container from '../Container/Container';
import INITIAL_VALUES from '../Field/defaultProps';
import Loader from '../Loader/Loader';
import classes from './Question.module.scss';

export interface QuestionProps extends Omit<React.HTMLProps<HTMLDivElement>, 'onChange'> {
  salesforceKey: string,
  secondarySalesforceKey?: string,
  title?: string,
  description?: string,
  fields?: FieldProps[],
  secondaryFields?: FieldProps[],
  bottomText?: string,
  stepText?: string,
  questionIndex?: number,
  questionsLength?: number,
  answers?: any[],
  loading?: boolean,
  currentHeight?: number | string,

  onKeyDown?(): void;

  onChange?(value: any, question: number, field: number): void;

  toggleHeight?(questionIndex: number, newHeight: number): void;
}

export default function Question({
  className,
  salesforceKey,
  secondarySalesforceKey,
  title,
  description,
  fields,
  secondaryFields,
  stepText,
  questionIndex = 0,
  bottomText,
  questionsLength = 0,
  onChange,
  toggleHeight,
  currentHeight,
  answers,
  loading,
  onKeyDown,
  ...others
}: QuestionProps) {
  if (loading) {
    return (
      <div
        className={classes.loadingBlock}
        style={{
          width: `calc(100% / ${questionsLength})`,
          height: currentHeight,
        }}
      >
        <div className={classes.loading}>
          <Loader size={50} />
        </div>
      </div>
    );
  }

  const mapFields = (elements: FieldProps[], isSecondary?: boolean) => (
    <Container size="medium" className={cx(classes.fieldsContainer, { [classes.secondary]: isSecondary })}>
      {elements?.map(({ key, onChange: _, type, ...rest }: FieldProps, index) => (
        <Field
          key={key}
          type={type}
          className={elements.length % 2 === 1 && index === elements.length - 1 && elements?.length > 1 ? classes.last : ''}
          value={answers?.[isSecondary ? (fields?.length || 0) + index : index] || INITIAL_VALUES[type]}
          onChange={(val: any) => {
            if (onChange) {
              onChange(val, questionIndex, isSecondary ? (fields?.length || 0) + index : index);
            }
          }}
          onKeyDown={() => (type === 'input' && onKeyDown ? onKeyDown() : {})}
          {...(type === 'checkbox_input' ? {
            toggleHeight: (val: number) => {
              if (toggleHeight) {
                toggleHeight(index, val);
              }
            },
          } : {})}
          fullWidth={isSecondary && elements.length === 1}
          halfWidth={elements.length % 2 === 1 && index === elements.length - 1 && elements?.length > 1}
          {...rest}
          fieldIndex={isSecondary ? (fields?.length || 0) + index : index}
        />
      ))}
    </Container>
  );

  return (
    <div
      className={cx(classes.questionBlock, className)}
      style={{
        width: `calc(100% / ${questionsLength})`,
      }}
      {...others}
    >
      <div className={classes.question} data-label="question">
        <Text component="h2" className={classes.title}>{`${stepText || ''} ${questionIndex + 1}: ${title}`}</Text>
        <Text className={classes.description}>{description}</Text>
        {fields && (mapFields(fields))}
        {secondaryFields && (mapFields(secondaryFields, true))}
      </div>
    </div>
  );
}
