import React from 'react';
import cx from 'clsx';
import classes from './Loader.module.scss';

interface LoaderProps {
  className?: string;
  style?: Record<string, any>;
  size?: number;
}

export default function Loader({ className, size = 40, style }: LoaderProps) {
  return (
    <div
      className={cx(classes.loader, className)}
      style={{
        ...style,
        width: size,
        height: size,
        borderWidth: Math.max(Math.round(size / 6), 2),
      }}
    />
  );
}
