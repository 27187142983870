import React, { useEffect } from 'react';
import cx from 'clsx';
import classes from './Video.module.scss';

export interface VideoProps extends React.HTMLProps<HTMLDivElement> {
}

export default function Video({
  className,
  src,
  width = 960,
  ...others
}: VideoProps) {
  if (!src) {
    return null;
  }

  return (
    <div className={cx(classes.video, className)} {...others}>
      <iframe
        allowFullScreen
        allow="encrypted-media *"
        width={width}
        src={src}
      />
    </div>
  );
}
