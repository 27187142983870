import React from 'react';
import cx from 'clsx';
import { Entry } from 'contentful';
import { ButtonType, HeroType, MetaType } from '../../util/contentfulTypes';
import Hero from '../Hero/Hero';
import contentMapper from './contentMapper';
import classes from './Page.module.scss';
import Meta from '../Meta/Meta';

export interface PageProps extends Omit<React.HTMLProps<HTMLDivElement>, 'content'> {
  meta?: Entry<MetaType>
  slug: string,
  content?: Entry<any>[],
  hero?: Entry<HeroType>,
  scrolledNavigation?: boolean,
  withNotification?: boolean,
  baseURL?: string,
  pathname?: string,
  locale?: string,
}

export default function Page({
  className,
  meta,
  slug,
  content,
  hero,
  scrolledNavigation,
  withNotification,
  baseURL,
  pathname,
  locale,
  ...others
}: PageProps) {
  return (
    <div {...others}>
      {meta && (
        <Meta
          {...(meta.fields || {})}
          baseURL={baseURL}
          pathname={pathname}
          locale={locale}
        />
      )}
      <div style={{ height: withNotification ? 55 : 0, transition: 'height 200ms ease' }} />
      {hero && hero.fields && (
        <Hero
          {...hero.fields}
          backgroundImage={hero.fields?.backgroundImage?.fields?.file?.url}
          backgroundImageAlt={hero.fields?.backgroundImage?.fields.description}
          buttons={hero.fields.buttons?.map((button: Entry<ButtonType>) => button.fields) || []}
          image={hero.fields?.image?.fields?.file?.url}
          scrollDownButton={hero.fields?.scrollDownButton?.fields}
        />
      )}
      <div className={cx({ [classes.contentWrapper]: !hero || !hero.fields })}>
        {content && content.length > 0 && (
          content.map(item => {
            //@ts-ignore
            const Element = contentMapper[item?.sys?.contentType?.sys?.id];
            if (Element) {
              return <Element {...item.fields} />;
            }
            return null;
          })
        )}
      </div>
    </div>
  );
}
