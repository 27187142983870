import React, { useRef, useState } from 'react';
import cx from 'clsx';
import breakpoints from '../../styles/breakpoints';
import TopBorderCardContainer from '../TopBorderCardContainer/TopBorderCardContainer';
import { TopBorderCardProps } from '../TopBorderCardContainer/TopBorderCard/TopBorderCard';
import SimpleButton from '../SimpleButton/SimpleButton';
import Container from '../Container/Container';
import Text from '../Text/Text';
import useWindowWidth from '../../hooks/useWindowWidth';
import classes from './CardCarousel.module.scss';
import { number } from 'prop-types';

export interface CardCarouselProps extends React.HTMLProps<HTMLDivElement> {
  cards?: TopBorderCardProps[],
  title?: string,
  description?: string,
}

export default function CardCarousel({
  className,
  title,
  description,
  cards = [],
  ...others
}: CardCarouselProps) {
  const [cardsWidth, setCardsWidth] = useState(1200);
  const [cardWidth, setCardWidth] = useState(300);
  const [activeCard, setActiveCard] = useState(0);
  const [scrolledWidth, setScrolledWidth] = useState(0);
  const cardsRef = useRef<HTMLDivElement>(null);
  const windowWidth = useWindowWidth(1200);

  const onThumbnailClick = (index: number) => {
    setActiveCard(index);
    setScrolledWidth((breakpoints.largeMobile < windowWidth ? cardsWidth / cards?.length : cardsWidth) * index - 15 * index);
  };

  const calcCardWidth = (width: number) => {
    if (windowWidth > breakpoints.tablet) {
      if (cards.length < 3) {
        return (cardsWidth / 2) - 51;
      }
      return (cardsWidth / 3) - 51;
    }
    if (windowWidth < breakpoints.tablet + 1 && windowWidth > breakpoints.largeMobile) {
      if (cards.length < 4) {
        return (cardsWidth - 125) / 3;
      } else {
        return (cardsWidth / 3) - 51;
      }
    }
    if (windowWidth < breakpoints.largeMobile + 1) {
      return undefined;
    }
  };

  const updateCardsWidth = (width: number) => {
    const fixWidth = width || cardsRef?.current?.offsetWidth || 0;
    setCardsWidth(fixWidth);
    setCardWidth(calcCardWidth(fixWidth) || 0);
  };

  const handleScroll = (width: number) => {
    setActiveCard(Math.round(width / (
      breakpoints.largeMobile < windowWidth ? cardsWidth / cards?.length  : cardsWidth),
    ));
  }

  return (
    <div className={cx(classes.cardCarousel, className)} tabIndex={0} {...others}>
      <Container size="large">
        <div className={classes.content}>
          {title && <Text component="h2" className={classes.title}>{title}</Text>}
          {description && <Text className={classes.description}>{description}</Text>}
        </div>
        {cards && cards.length > 0 && (
          <div className={classes.cardsContainer} ref={cardsRef} tabIndex={0}>
            <TopBorderCardContainer
              cards={cards}
              absolute
              scrolledWidth={scrolledWidth}
              cardWidth={cardWidth}
              updateCardsWidth={updateCardsWidth}
              updateScrollWidth={handleScroll}
            />
          </div>
        )}
        {cards && cards.length > 0 && (
          <div
            className={cx(classes.thumbnails, {
              [classes.hide]: cards.length < 3,
            })}
          >
            {(windowWidth > breakpoints.largeMobile ? cards.length > 3 : cards.length > 1) && cards.slice(0, cards.length).map((card, index) => (
              <SimpleButton
                className={cx(classes.thumbnail, { [classes.active]: index === activeCard })}
                key={index}
                component="button"
                onClick={() => onThumbnailClick(index)}
                ariaLabel={`card ${index + 1}`}
              />
            ))}
          </div>
        )}
      </Container>
    </div>
  );
}
