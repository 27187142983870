import React, { useContext } from 'react';
import cx from 'clsx';
import Video from '../Video/Video';
import CircleImage from '../CircleImage/CircleImage';
import Container from '../Container/Container';
import { ModalContext } from '../Modal/ModalContext';
import classes from './ImageBlockWrapper.module.scss';

export interface ImageBlockWrapperProps extends React.HTMLProps<HTMLDivElement> {
  theme?: 'dark' | 'light',
  image?: string,
  imageAlt?: string,
  imageRight?: boolean,
  withVideo?: boolean,
  video?: string,
  modalWidth?: number,
}

export default function ImageBlockWrapper({
  className,
  theme = 'light',
  image,
  imageAlt,
  imageRight,
  withVideo,
  video,
  children,
  modalWidth = 1200,
  ...others
}: ImageBlockWrapperProps) {
  const { handleModal } = useContext(ModalContext);

  const videoComponent = (src: string) => <Video src={src} />;

  return (
    //@ts-ignore
    <Container
      size="large"
      className={cx(
        classes.block,
        classes[theme],
        {
          [classes.withImage]: image,
          [classes.imageRight]: imageRight,
        },
        className,
      )}
      tabIndex={0}
      {...others}
    >
      {image && (
        <div className={classes.imageContainer}>
          <CircleImage
            image={image}
            component={withVideo && video ? 'button' : 'div'}
            className={cx(classes.videoButton, { [classes.clickable]: withVideo && video })}
            onClick={() => {
              if (withVideo && video) {
                handleModal(videoComponent(video));
              }
            }}
            alt={imageAlt || ''}
            play={!!(withVideo && video) || false}
          />
        </div>
      )}
      {children}
    </Container>
  );
}
