const colors = {
  darkBlue: '#171C8F',
  darkBlueHover: '#121672',
  burnBlue: '#2839d0',
  blue: '#009ACE',
  charcoal: '#3D3935',
  lightGray: '#EFEFED',
  semiLightGray: '#C4C4C4',
  gray: '#868685',
  textGray: '#666666',
  orange: '#FFA300',
};

export default colors;
