import React from 'react';
import cx from 'clsx';
import PlayIcon from '../icons/Play';
import classes from './CircleImage.module.scss';

export interface CircleImageProps<T> extends React.HTMLProps<T> {
  className?: string;
  component?: React.ComponentType<T> | React.ElementType,
  image?: string,
  alt?: string,
  play?: boolean,
  ariaLabel?: string,
}

export default function CircleImage<T  = 'div'>({
  className,
  component,
  image,
  alt,
  ariaLabel,
  play,
  ...others
}: CircleImageProps<T>) {
  const Element = component || 'div';

  if (!image) {
    return null;
  }

  return (
    <Element
      className={cx(classes.circleImage, className)}
      {...(component === 'button' ? { ['aria-label']: alt || "Play button" } : {})}
      {...others}
    >
      {play && <PlayIcon className={classes.play} size={54} />}
      <img className={classes.image} src={image} alt={alt || ""} />
    </Element>
  );
}
