import BugsnagClient from '../../../util/bugsnag';
import { CreateDataType } from '../../../util/types';

export default async function createData(data: CreateDataType) {
  const requestResult = await fetch('/api/create-data', {
    method: 'POST',
    body: JSON.stringify(data),
  })
    .then(response => response.json())
    .catch((err) => {
      BugsnagClient?.notify(err);
      return { success: false };
    });

  if (requestResult?.success) {
    document.cookie = `sfUser=${requestResult.sfToken};Max-Age=300`;
  }

  return requestResult;
}
