// import getCountry from '../../../util/getCountry';
import type { TextType } from '../../../util/contentfulTypes';
import type { GeolocationType, LoginResultType, RegionsType } from '../../../util/types';
import getRegion from '../../../util/getRegion';

type Errors = {
  clinIdError?: TextType,
  serverError: TextType,
  countryError?: TextType,
  alreadyExistsError?: TextType,
  redirectError?: TextType,
}

interface ProcessLoginType {
  errors: Errors,
  loginResult: LoginResultType,
  loginValue?: string,
  geolocation?: GeolocationType
}

export function getError(serverError: TextType, error?: TextType) {
  return error ? error.description || error.title : serverError.description || serverError.title;
}

export default async function processLogin({
  errors,
  loginResult,
  loginValue,
  geolocation,
}: ProcessLoginType) {
  let createInProcess = false;
  if (!loginResult?.success) {
    if (loginResult.error) {
      if (loginResult.error === 'no_user') {
        return {
          error: getError(errors.serverError, errors.clinIdError),
          success: false,
        };
      }
      return {
        error: getError(errors.serverError),
        success: false,
      };
    }
    if (loginResult.code || loginResult.errno) {
      return {
        error: getError(errors.serverError),
        success: false,
      };
    }
    return {
      error: getError(errors.serverError, errors.clinIdError),
      success: false,
    };
  }

  if (loginResult.isExist) {
    return {
      error: getError(errors.serverError, errors.alreadyExistsError),
      success: false,
    };
  }

  let region: keyof RegionsType | undefined | null = geolocation?.region;

  if (!geolocation?.region && geolocation?.country) {
    region = await fetch('/api/get-region', {
      method: 'POST',
      body: JSON.stringify({ country: geolocation?.country }),
    }).then(res => res.json());
  }

  if (!region) {
    return {
      error: getError(errors.serverError),
      success: false,
    };
  }

  if (region !== loginResult.region) {
    return {
      error: getError(errors.serverError, errors.countryError),
      success: false,
    };
  }

  //TODO
  // if (redirect_countries.includes(getCountryCode(loginResult.country).toLowerCase())) {
  //   setErrorMessage(getError(errors.serverError, errors.redirectError));
  //   setLoading(false);
  //   return;
  // }

  //TODO
  // if (!survey_countries.includes(getCountryCode(loginResult.country).toLowerCase()) ||
  //   ((currentRegion || 'NA') !== (getTargetRegion(getCountryCode(loginResult.country)) || 'NA'))) {
  //   setErrorMessage(getError(errors.serverError, errors.countryError));
  //   setLoading(false);
  // } else {


  if (!loginValue) {
    return {
      error: getError(errors.serverError, errors.clinIdError),
      success: false,
    };
  }

  return {
    error: null,
    success: true,
  };
}
