import React from 'react';

export default function RoundedShape({ className }: { className?: string }) {
  return (
    <svg
      fill="none"
      viewBox="0 0 1600 111"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(.clipPathClass)">
        <path
          d="M800 106C433.5 106 120.9 54.667 0 .832V111h1600V.832C1479.1 54.667 1166.5 106 800 106z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath className="clipPathClass">
          <path d="M0 0h1600v111H0z" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
}
