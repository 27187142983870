import React from 'react';
import cx from 'clsx';
import fieldsMap from './fieldMap';
import classes from './Field.module.scss';

export interface FieldProps extends React.HTMLProps<HTMLDivElement> {
  key?: string,
  label?: string,
  points?: number
  sliderMax?: number,
  type: 'input' | 'checkbox' | 'checkbox_input' | 'slider' | 'textarea',
  placeholder?: string,
  value?: any,
  fullWidth?: boolean,
  halfWidth?: boolean,
  fullContainerWidth?: boolean,
  fieldIndex?: number,

  onChange?(value: any): void;
}

export default function Field({
  className,
  type,
  sliderMax,
  points,
  onChange,
  fullContainerWidth,
  fieldIndex,
  ...others
}: FieldProps) {
  if (!type) {
    return null;
  }

  const Element = type in fieldsMap ? fieldsMap[type] : null;

  if (!Element) {
    return null;
  }

  return (
    <div
      className={cx(classes.field, className, { [classes.fullWidth]: fullContainerWidth })}
      data-index={fieldIndex}
      data-label="applicationField"
    >
      {/*  @ts-ignore*/}
      <Element onChange={onChange} {...others} />
    </div>
  );
}
