import { QuestionProps } from '../Question/Question';
import { FieldProps } from '../Field/Field';
import INITIAL_VALUES from '../Field/defaultProps';

export default function mapDefaultValues(questions?: QuestionProps[]) {
  return !questions ? [] : questions
    .map((question, index) => (
        [
          ...(question.fields?.map((field: FieldProps, j) => (INITIAL_VALUES[field.type])) || []),
          ...(question.secondaryFields?.map((field: FieldProps, j) => (INITIAL_VALUES[field.type])) || []),
        ]
      ),
    );
}
