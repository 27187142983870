type RecordType = {
  code: string,
  name: string,
}

export type RecordTypes = {
  countries: RecordType[],
  regions: RecordType[],
  default: RecordType,
}
export default function getRecordType(recordTypes: RecordTypes, currentCountry?: string, currentRegion?: string | null ) {
  let type = recordTypes.countries.find((country: RecordType) => country.code === currentCountry?.toUpperCase());
  if (!type && currentRegion) {
    type = recordTypes.regions.find((region: RecordType) => region.code === currentRegion.toUpperCase())
  }

  if (!type) {
    type = recordTypes.default;
  }

  return type?.name;
}
