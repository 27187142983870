import React from 'react';
import cx from 'clsx';
import Text from '../Text/Text';
import classes from './TextBlock.module.scss';
import Container from '../Container/Container';

export interface TextBlockProps extends React.HTMLProps<HTMLDivElement>{
  title?: string,
  description?: string,
}

export default function TextBlock({
  className,
  title,
  description,
  ...others
}: TextBlockProps) {
  return (
    <Container size="large" className={cx(classes.textBlock, className)} {...others}>
      {title && <Text className={classes.title}>{title}</Text>}
      {description && <Text className={classes.description}>{description}</Text>}
    </Container>
  )
}
