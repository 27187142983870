import React from 'react';
import cx from 'clsx';
import { ButtonType } from '../../../util/contentfulTypes';
import colors from '../../../styles/colors';
import Text from '../../Text/Text';
import Button from '../../Button/Button';
import iconMap from '../../icons/iconMap';
import classes from './TopBorderCard.module.scss';

export interface TopBorderCardProps extends React.HTMLProps<HTMLDivElement> {
  topBorderColor?: string,
  image?: string,
  imageAlt?: string,
  imageHeight?: number,
  title?: string,
  titleIcon?: keyof typeof iconMap,
  description?: string,
  button?: ButtonType,
  width?: number,
  index?: number,
}

export default function TopBorderCard({
  className,
  topBorderColor = colors.blue,
  image,
  imageAlt,
  imageHeight = 250,
  title,
  titleIcon,
  description,
  button,
  width = 0,
  style,
  index,
  ...others
}: TopBorderCardProps) {
  const Icon = titleIcon && Object.keys(iconMap).includes(titleIcon) ? iconMap[titleIcon] : null;

  return (
    <div
      className={cx(classes.topBorderCard, className)}
      style={{
        ...style,
        width: width === 0 ? '100%' : width,
        minWidth: width === 0 ? '100%' : width,
      }}
      {...(button ? {} : { tabIndex: typeof index === 'number' ? index : -1 })}
      {...others}
    >
      <div className={classes.topBorder} style={{ backgroundColor: topBorderColor }} />
      {image && <img className={classes.image} src={image} alt={imageAlt || ''} height={imageHeight} />}
      {(title || description || button) && (
        <div
          className={classes.content}
          style={{ height: image ? `calc(100% - ${imageHeight}px - 7px)` : 'calc(100% - 7px)' }}
        >
          <div className={classes.topContent}>
            {Icon && <Icon style={{ color: topBorderColor, marginBottom: 35 }} />}
            {title && <Text component="h3" className={classes.title}>{title}</Text>}
            {description && <Text className={classes.description}>{description}</Text>}
          </div>
          <div className={classes.bottomContent}>
            {button && (
              <Button
                tabIndex={index || 0}
                className={classes.button}
                style={width - 50 > 235 ? {} : {
                  minWidth: '100%',
                  maxWidth: '100%',
                }}
                {...button}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
