import React from 'react';
import Head from 'next/head';
import { Asset } from 'contentful';

export interface MetaProps {
  seoTitle?: string,
  baseURL?: string,
  image?: Asset,
  twitter_user?: string,
  description?: string,
  locale?: string,
  children?: React.ReactNode,
  pathname?: string,
  no_index?: boolean,
  no_follow?: boolean,
  keywords?: string[],
}

export default function Meta({
  seoTitle,
  baseURL,
  image,
  twitter_user,
  description,
  locale,
  children,
  no_index,
  no_follow,
  pathname,
  keywords,
}: MetaProps) {
  const getRobotsTag = () => {
    if (no_index && no_follow) {
      return 'noindex,nofollow';
    }
    if (no_follow) {
      return 'index,nofollow';
    }
    if (no_index) {
      return 'noindex,follow';
    }
    return 'index,follow';
  };


  return (
    <Head>
      <html lang={locale ? locale.slice(0, 2) : 'en'} />
      {seoTitle && <title>{seoTitle}</title>}
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      {description && <meta name="description" content={description} />}
      <meta name="theme-color" content="#3f2a56" />
      {locale && <meta httpEquiv="Content-Language" content={(locale || 'en-US').replace('-', '_')} />}
      <link rel="canonical" href={`${baseURL || 'https://cws-na-adapt-ui-qat.herokuapp.com/'}${pathname}`} />
      {keywords && <meta name="keywords" content={keywords.join(',')} />}
      {seoTitle && <meta property="og:title" content={seoTitle} />}
      {description && <meta property="og:description" content={description} />}
      {image && <meta property="og:image" content={image?.fields?.file?.url} />}
      {baseURL && <meta property="og:url" content={baseURL} />}
      {seoTitle && <meta property="og:site_name" content={seoTitle} />}
      {seoTitle && <meta name="twitter:title" content={seoTitle} />}
      {description && <meta name="twitter:description" content={description} />}
      {image && <meta name="twitter:image" content={image?.fields?.file?.url} />}
      {twitter_user && <meta name="twitter:site" content={twitter_user} />}
      {twitter_user && <meta name="twitter:creator" content={twitter_user} />}
      {(no_index || no_follow) && <meta name="robots" content={getRobotsTag()} />}
      {children}
    </Head>
  );
}
