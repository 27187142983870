import React, { useEffect, useRef, useState } from 'react';
import cx from 'clsx';
import useWindowWidth from '../../hooks/useWindowWidth';
import Container from '../Container/Container';
import Text from '../Text/Text';
import TextWithImage from '../TextWithImage/TextWithImage';
import classes from './IconsList.module.scss';

export interface CardCarouselProps extends React.HTMLProps<HTMLDivElement> {
  elements?: {
    image?: string,
    imageAlt?: string,
    description?: string,
    title?: string,
  }[],
  title?: string,
  description?: string,
}

export default function IconsList({
  className,
  title,
  description,
  elements = [],
  ...others
}: CardCarouselProps) {
  return (
    <div className={cx(classes.iconsList, className)} {...others} tabIndex={0}>
      <Container size="large" >
        <div className={classes.content}>
          {title && <Text component="h2" className={classes.title}>{title}</Text>}
          {description && <Text className={classes.description}>{description}</Text>}
        </div>
        <div className={classes.imagesList}>
          {elements?.map((element, index) => (
            <TextWithImage {...element} key={index} />
          ))}
        </div>
      </Container>
    </div>
  );
}
