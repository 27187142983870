import React, { useContext, useEffect, useRef, useState } from 'react';
import cx from 'clsx';
import Container from '../Container/Container';
import Button from '../Button/Button';
import RoundedShape from './RoundedShape';
import { ButtonType } from '../../util/contentfulTypes';
import Text from '../Text/Text';
import BREAKPOINTS from '../../styles/breakpoints';
import COLORS from '../../styles/colors';
import ButtonsContainer from '../ButtonsContainer/ButtonsContainer';
import CircleImage from '../CircleImage/CircleImage';
import Video from '../Video/Video';
import { ModalContext } from '../Modal/ModalContext';
import useWindowWidth from '../../hooks/useWindowWidth';
import classes from './Hero.module.scss';

export interface NavigationProps extends React.HTMLProps<HTMLDivElement> {
  className?: string,
  backgroundImage?: string,
  backgroundImageAlt?: string,
  imageRight?: boolean,
  contentAlign?: 'center' | 'left' | 'right'
  video?: string,
  image?: string,
  title?: string,
  description?: string,
  buttons?: ButtonType[],
  withVideo?: boolean,
  scrollDownButton?: ButtonType,
  overlayColor?: string,
  overlayOpacity?: number,
  ariaLabel?: string,
}

export default function Hero({
  className,
  backgroundImage,
  imageRight,
  backgroundImageAlt,
  video,
  image,
  title,
  description,
  buttons = [],
  contentAlign = 'left',
  withVideo,
  scrollDownButton,
  overlayColor = COLORS.darkBlue,
  overlayOpacity = 85,
  ariaLabel,
  ...others
}: NavigationProps) {
  const { handleModal } = useContext(ModalContext);
  const windowWidth = useWindowWidth(1060);
  const heroRef = useRef<HTMLDivElement>(null);

  const videoComponent = (src?: string) => (src ? <Video src={src} /> : null);
  const allButtons = scrollDownButton ? [...(buttons || []), scrollDownButton] : buttons;

  const scrollDown = () => {
    if (heroRef.current) {
      window.scrollTo({ top: heroRef.current.offsetHeight - 60, behavior: 'smooth' });
    }
  };

  return (
    <div className={cx(classes.hero, className)} ref={heroRef} {...others}>
      <div className={classes.backgroundImage}>
        <div
          className={classes.overlayColor}
          style={{
            backgroundColor: overlayColor,
            opacity: backgroundImage ? overlayOpacity / 100 : 1,
          }}
        />
        <img
          src={backgroundImage}
          alt={backgroundImageAlt || ''}
        />
        <RoundedShape className={classes.circular} />
      </div>
      <Container className={classes.container} size="large">
        <div
          className={classes.content}
          style={{
            justifyContent: video ?
              windowWidth > BREAKPOINTS.tablet ? 'space-between' : 'center' :
              'center',
            flexDirection: imageRight ?
              windowWidth > BREAKPOINTS.tablet ? 'row-reverse' : 'column' :
              'row',
          }}
        >
          {image && (
            <div className={classes.videoContainer}>
              <CircleImage
                image={image}
                component={withVideo && video ? 'button' : 'div'}
                className={cx(classes.videoButton, { [classes.clickable]: withVideo && video })}
                onClick={() => {
                  if (withVideo && video) {
                    handleModal(videoComponent(video), 1200);
                  }
                }}
                play={!!(withVideo && video) || false}
                ariaLabel={ariaLabel}
              />
            </div>
          )}
          <div
            className={classes.textBlock}
            style={windowWidth > BREAKPOINTS.tablet ?
              { alignItems: contentAlign === 'left' ? 'flex-start' : contentAlign === 'right' ? 'flex-end' : 'center' } :
              { alignItems: 'center' }
            }
          >
            {title && (
              <Text
                className={classes.title}
                style={{ textAlign: windowWidth > BREAKPOINTS.tablet ? contentAlign : 'center' }}
              >
                {title}
              </Text>
            )}
            {description && (
              <Text
                style={{ textAlign: windowWidth > BREAKPOINTS.tablet ? contentAlign : 'center' }}
                className={classes.description}
              >
                {description}
              </Text>
            )}
            {allButtons?.length > 0 && (
              <ButtonsContainer align={windowWidth > BREAKPOINTS.tablet ? contentAlign : 'center'}>
                {buttons?.map((button, index) => (
                  button.withVideo && button.videoUrl ? (
                    <Button
                      key={index}
                      className={classes.button}
                      component="button"
                      showVideo={(url) => handleModal(videoComponent(url), 1200)}
                      {...button}
                    />
                  ) : (
                    <Button key={index} className={classes.button} {...button} />
                  )
                ))}
                {scrollDownButton && (
                  <Button
                    key={(buttons?.length || 0) + 1}
                    className={classes.button}
                    component="button"
                    {...scrollDownButton}
                    onClick={scrollDown}
                  />
                )}
              </ButtonsContainer>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
}
