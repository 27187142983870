import React, { useEffect, useRef, useState } from 'react';
import cx from 'clsx';
import useWindowWidth from '../../hooks/useWindowWidth';
import Text from '../Text/Text';
import { TopBorderCardProps } from '../TopBorderCardContainer/TopBorderCard/TopBorderCard';
import ArrowButton from './ArrowButton/ArrowButton';
import Container from '../Container/Container';
import TopBorderCardContainer from '../TopBorderCardContainer/TopBorderCardContainer';
import breakpoints from '../../styles/breakpoints';
import classes from './Scroller.module.scss';

export interface ScrollerProps extends React.HTMLProps<HTMLDivElement> {
  title?: string,
  description?: string,
  cards?: TopBorderCardProps[],
  cardsWidth?: number,
  leftAriaLabel?: string,
  rightAriaLabel?: string
}

export default function Scroller({
  className,
  title,
  description,
  cards = [],
  cardsWidth: cardWidth = 300,
  leftAriaLabel,
  rightAriaLabel,
  ...others
}: ScrollerProps) {
  const [scrolledWidth, setScrolledWidth] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const windowWidth = useWindowWidth(1200);
  const [maxScrollWidth, setMaxScrollWidth] = useState(0);
  const [fullCardsWidth, setFullCardsWidth] = useState((cardWidth + 30) * cards?.length + 2); //2 for gap (-30) + paddings (+32)
  let preventScroll = false;

  const scrollLeft = () => {
    preventScroll = true;
    setScrolledWidth(scrolledWidth - cardWidth < 0 ? 0 : scrolledWidth - cardWidth);
  };

  const scrollRight = () => {
    preventScroll = true;
    const lastScroll = scrolledWidth + cardWidth > maxScrollWidth ? maxScrollWidth : scrolledWidth + cardWidth;

    setScrolledWidth(scrolledWidth + cardWidth * 2 < fullCardsWidth - maxScrollWidth ? maxScrollWidth : lastScroll);
  };

  const updateScrollWidth = async (width: number) => {
    setScrolledWidth(width > maxScrollWidth ? maxScrollWidth : width);
    // }
  };

  useEffect(() => {
    if (ref.current) {
      setMaxScrollWidth(fullCardsWidth - ref.current.offsetWidth + (
        windowWidth > breakpoints.largeMobile ? 0 : 18
      ));
      setScrolledWidth(0);
    }
  }, [ref, windowWidth]);

  return (
    <Container size="large" className={cx(classes.scroller, className)} {...others}>
      <Container size="large" className={classes.content}>
        {title && <Text component="h2" className={classes.title}>{title}</Text>}
        {description && <Text className={classes.description}>{description}</Text>}
        <div className={classes.buttons}>
          <ArrowButton
            direction="left"
            onClick={scrollLeft}
            disabled={scrolledWidth === 0}
            aria-label={leftAriaLabel}
          />
          <ArrowButton
            direction="right"
            onClick={scrollRight}
            disabled={scrolledWidth + 19 > maxScrollWidth}
            aria-label={rightAriaLabel}
          />
        </div>
      </Container>
      <div className={classes.cards} ref={ref} tabIndex={0}>
        <TopBorderCardContainer
          className={classes.cardsContainer}
          cardWidth={cardWidth}
          cards={cards}
          absolute
          scrolledWidth={scrolledWidth}
          updateScrollWidth={updateScrollWidth}
          updateCardsWidth={() => {
          }}
        />
      </div>
    </Container>
  );
}
