import React from 'react';
import cx from 'clsx';
import { ButtonType } from '../../../util/contentfulTypes';
import Text from '../../Text/Text';
import ButtonsContainer from '../../ButtonsContainer/ButtonsContainer';
import Button from '../../Button/Button';
import classes from './Column.module.scss';

export interface ColumnProps extends React.HTMLProps<HTMLDivElement> {
  theme: 'dark' | 'light',
  subtitle?: string,
  title?: string,
  description?: string,
  buttons?: ButtonType[],
  buttonsAlign?: 'center' | 'left' | 'right'
  listColumns?: number,
  width?: number,
  style?: Record<string, any>,
  hideSubtitle?: boolean,
}

export default function Column({
  className,
  subtitle,
  title,
  description,
  buttons,
  buttonsAlign,
  width = 50,
  listColumns = 1,
  style,
  theme = 'light',
  hideSubtitle = false,
  ...others
}: ColumnProps) {
  return (
    <div
      className={cx(classes.column, classes[theme], className)}
      style={{
        ...style,
        flex: `${width}%`,
      }}
      {...others}
    >
      <div className={classes.content}>
        {!hideSubtitle && <Text className={classes.subtitle} subtitle>{subtitle}</Text>}
        {title && <Text className={classes.title}>{title}</Text>}
        {description && (
          <Text className={classes.description} style={{ columns: listColumns }}>
            {description}
          </Text>
        )}
        {buttons && (
          <ButtonsContainer
            align={buttonsAlign}
            className={cx(classes.buttonsContainer, { [classes.noMargin]: !description })}
          >
            {buttons.map((button, i) => (
              <Button className={classes.button} key={i} {...button} darkBackground={theme === 'dark'} />
            ))}
          </ButtonsContainer>
        )}
      </div>
    </div>
  );
}
