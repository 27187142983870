import TextInput from './components/TextInput/TextInput';
import Checkbox from './components/Checkbox/Checkbox';
import CheckboxInput from './components/CheckboxInput/CheckboxInput';
import Slider from '../Slider/Slider';

const map = {
  input: TextInput,
  checkbox: Checkbox,
  checkbox_input: (props: any) => <CheckboxInput component="button" {...props} />,
  slider: Slider,
  textarea: (props: any) => <TextInput component="textarea" {...props} />,
};

export default map;
