import React from 'react';
import cx from 'clsx';
import classes from './LogoGallery.module.scss';

export interface LogoGalleryProps extends React.HTMLProps<HTMLDivElement> {
  images?: { src: string, alt?: string }[];
}

export default function LogoGallery({
  className,
  images = [],
  ...others
}: LogoGalleryProps) {
  return (
    <div className={cx(classes.logoGallery, className)} {...others}>
      <div className={classes.inner} style={{ animationDuration: `${images?.length * 10}s` }}>
        {[...images, ...images].map((image, index) => (
          <div className={classes.imageWrapper} key={index}>
            <img className={classes.image} alt={image.alt || ''} src={image.src} />
          </div>
        ))}
      </div>
    </div>
  );
}
