import React, { useState } from 'react';
import cx from 'clsx';
import { CSSTransition } from 'react-transition-group';
import Checkbox, { CheckboxProps } from '../Checkbox/Checkbox';
import classes from './CheckboxInput.module.scss';

interface CheckboxInputProps extends Omit<CheckboxProps, 'onChange'> {
  value: string;
  trackingKey?: string,
  placeholder?: string,
  sliderMax?: number,
  error?: string,
  showError?: boolean,
  fullWidth?: boolean,
  halfWidth?: boolean,
  label?: string,

  onFocus?(): void,

  onBlur?(): void,

  onChange(value: string): void;

  toggleHeight(height: number): void;
}

export default function CheckboxInput({
  className,
  value = '',
  onChange,
  onFocus,
  onBlur,
  placeholder,
  trackingKey,
  sliderMax,
  showError,
  fullWidth,
  halfWidth,
  toggleHeight = () => {
  },
  label,
  ...others
}: CheckboxInputProps) {
  const [checkValue, setCheckValue] = useState(value !== '');

  const handleCheckboxChange = () => {
    if (checkValue) {
      onChange('');
    }

    toggleHeight(checkValue ? -110 : 110);
    setCheckValue(!checkValue);
  };

  return (
    <Checkbox
      className={cx(classes.checkbox, className)}
      onChange={handleCheckboxChange}
      // style={{ height: checkValue ? 167 : 50 }}
      value={checkValue}
      trackingKey={trackingKey}
      checkboxInput
      showError={showError}
      halfWidth={halfWidth}
      fullWidth={fullWidth}
      label={label}
      {...others}
    >
      <CSSTransition
        in={checkValue}
        timeout={200}
        classNames={classes}
        unmountOnExit
      >
        <textarea
          className={cx(classes.input, {[classes.error]: showError})}
          value={value}
          onFocus={onFocus}
          onBlur={onBlur}
          placeholder={placeholder}
          disabled={!checkValue}
          onChange={event => onChange(event.target.value)}
        />
      </CSSTransition>
    </Checkbox>
  );
}
