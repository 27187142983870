import React from 'react';
import cx from 'clsx';
import Text from '../../../Text/Text';
import classes from './InputWrapper.module.scss';

export interface InputWrapperProps<T = {}> extends React.HTMLProps<T>{
  error?: string | Record<string, any>,
  showError?: boolean,
  withLabel?: boolean,
  component?: React.ComponentType<T> | React.ElementType,
  active?: boolean,
  fullWidth?: boolean
  checkboxInput?: boolean,
  withoutError?: boolean,
  noHover?: boolean,
}

export default function InputWrapper<T = 'div'>({
  className,
  component: Element = 'div',
  children,
  withLabel,
  error,
  showError,
  active,
  fullWidth = false,
  checkboxInput = false,
  withoutError = false,
  noHover = false,
  ...others
}: InputWrapperProps<T>) {
  return (
    <Element className={cx(classes.inputWrapper, className)} {...others}>
      <div
        className={cx(classes.inner, {
          [classes.error]: showError,
          [classes.withLabel]: withLabel,
          [classes.active]: active,
          [classes.fullWidth]: fullWidth,
          [classes.column]: checkboxInput,
          [classes.noHover]: noHover
        })}
      >
        {children}
        <div className={cx(classes.errorIconWrapper, { [classes.show]: showError })}>
          <div className={classes.errorIcon}>
            !
          </div>
        </div>
      </div>
      {!withoutError && (
        <div style={{ height: 30 }}>
          <Text data-input-error className={cx(classes.errorText, { [classes.show]: showError })}>
            {error}
          </Text>
        </div>
      )}
    </Element>
  );
}
