import React from 'react';
import cx from 'clsx';
import { ButtonType } from '../../util/contentfulTypes';
import Text from '../Text/Text';
import ButtonsContainer from '../ButtonsContainer/ButtonsContainer';
import Button from '../Button/Button';
import AnimatedTitle from '../AnimatedTitle/AnimatedTitle';
import ImageBlockWrapper from '../ImageBlockWrapper/ImageBlockWrapper';
import classes from './Block.module.scss';

export interface BlockProps extends React.HTMLProps<HTMLDivElement> {
  theme?: 'dark' | 'light',
  image?: string,
  imageAlt?: string,
  imageRight?: boolean,
  subtitle?: string,
  title?: string,
  description?: string,
  cards?: {
    title?: string,
    titleOrder?: number,
    subtitle?: string,
  }[],
  buttons?: ButtonType[],
  withVideo?: boolean,
  video?: string,
  buttonsAlign?: 'center' | 'left' | 'right',
}

export default function Block({
  className,
  theme = 'light',
  image,
  imageAlt,
  imageRight,
  subtitle,
  title,
  description,
  cards,
  buttons,
  withVideo,
  video,
  buttonsAlign,
  ...others
}: BlockProps) {
  return (
    <div className={cx(classes.blockContainer, { [classes.paddings]: theme === 'dark' }, className)} {...others}>
      <ImageBlockWrapper
        className={cx(classes.block, classes[theme], className)}
        image={image}
        imageAlt={imageAlt}
        imageRight={imageRight}
        video={video}
        withVideo={withVideo}
        theme={theme}
      >
        <div className={classes.content}>
          {subtitle && <Text className={classes.subtitle} subtitle>{subtitle}</Text>}
          {title && <Text className={classes.title}>{title}</Text>}
          {description && <Text className={classes.description}>{description}</Text>}
          {cards && cards.length > 0 && (
            <div className={classes.cards}>
              {cards.map((card, index) => (
                <div className={classes.card} key={index}>
                  <AnimatedTitle
                    order={card.titleOrder}
                    className={classes.animatedTitle}
                    text={card.title}
                  />
                  <Text className={classes.animatedSubtitle}>{card.subtitle}</Text>
                </div>
              ))}
            </div>
          )}
          {buttons && (
            <ButtonsContainer
              align={buttonsAlign}
              className={cx(classes.buttonsContainer, { [classes.noMargin]: !description })}
            >
              {buttons.map((button, i) => (
                <Button className={classes.button} key={i} {...button} darkBackground={theme === 'dark'} />
              ))}
            </ButtonsContainer>
          )}
        </div>
      </ImageBlockWrapper>
    </div>
  );
}
