import React, { useEffect, useRef, useState } from 'react';
import cx from 'clsx';
import { useRouter } from 'next/router';
import cookie from 'cookie';
import type { LoginResultType, RegionsType, GeolocationType, OwnerType } from '../../util/types';
import Result, { ResultProps } from '../Result/Result';
import Question, { QuestionProps } from '../Question/Question';
import mapDefaultValues from './mapDefaultValues';
import QuestionWrapper from '../QuestionWrapper/QuestionWrapper';
import Stepper from '../Stepper/Stepper';
import Text from '../Text/Text';
import useWindowWidth from '../../hooks/useWindowWidth';
import useCleanCookies from '../../hooks/useCleanCookies';
import checkFilled from './helpers/checkFilled';
import getRecordType, { RecordTypes } from './helpers/getRecordType';
import getOwner from './helpers/getOwner';
import loginRequest from './helpers/login';
import createDataRequest from './helpers/createData';
import getSubmitData from './helpers/getSubmitData';
import { TextType } from '../../util/contentfulTypes';
import processLogin, { getError } from './helpers/processLogin';
import { mapSfFields } from '../../api/surveyUtils';
import classes from './Application.module.scss';
import getResultScreen from './helpers/getResultScreen';
import cleanSurveyCookies from '../../util/cleanSurveyCookies';

export interface ApplicationProps extends React.HTMLProps<HTMLDivElement> {
  middleResult?: ResultProps,
  negativeResult?: ResultProps,
  positiveResult: ResultProps,
  nextButtonLabel?: string,
  previousButtonLabel?: string,
  questions?: QuestionProps[],
  resultsTitle?: string,
  stepText?: string,
  subtitle?: string,
  nextButtonTrackingKey?: string,
  submitButtonTrackingKey?: string,
  owners: OwnerType[],
  recordTypes: RecordTypes,
  clinIdError?: TextType,
  serverError: TextType,
  countryError?: TextType,
  alreadyExistsError?: TextType,
  redirectError?: TextType,
  positivePoint?: number,
  neutralPoint?: number
}

export default function Application({
  className,
  middleResult,
  negativeResult,
  positiveResult,
  nextButtonLabel,
  previousButtonLabel,
  questions,
  resultsTitle,
  stepText,
  subtitle,
  nextButtonTrackingKey,
  submitButtonTrackingKey,
  owners,
  recordTypes,
  clinIdError,
  serverError,
  countryError,
  alreadyExistsError,
  redirectError,
  positivePoint,
  neutralPoint,
  ...others
}: ApplicationProps) {
  const router = useRouter();
  const windowWidth = useWindowWidth(1050);
  const reloadCookies = useCleanCookies(true);
  const [resultScreen, setResultScreen] = useState<ResultProps | undefined>(undefined);
  const [geolocation, setGeolocation] = useState<GeolocationType | undefined>(undefined);
  const [currentStep, setCurrentStep] = useState(0);
  const [filledStep, setFilledStep] = useState(-1);
  const [bottomText, setBottomText] = useState(questions?.[0]?.bottomText || null);
  const [currentHeight, setCurrentHeight] = useState(220);
  const [heights, setHeights] = useState<number[]>([]);
  const [answers, setAnswers] = useState(mapDefaultValues(questions));
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<LoginResultType | undefined>(undefined);
  const [createDataState, setCreateDataState] = useState<'loading' | 'finished' | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const applicationRef = useRef<HTMLDivElement>(null);

  const setStep = async (newStep: number) => {
    setCurrentStep(newStep);
    setCurrentHeight(heights[newStep]);
    setBottomText(questions?.[newStep]?.bottomText || null);
    const query = newStep === questions?.length ? 'result' : newStep + 1;
    const pathname = router.asPath.slice(0, router.asPath.indexOf('?'));
    if (applicationRef.current) {
      window.scrollTo({ top: applicationRef.current.getBoundingClientRect().top + window.scrollY });
    }
    setTimeout(() => (
      router.push({
        query: {
          slug: `${pathname}?${query}`,
        },
      }, `${pathname}?${query}`, { shallow: true })
    ), 100);

  };

  const toggleQuestionHeight = (questionIndex: number, newHeight: number) => {
    const newHeights = [...heights];
    newHeights[questionIndex - 1] = heights[questionIndex - 1] + newHeight;
    setHeights(newHeights);
    setCurrentHeight(heights[questionIndex - 1] + newHeight);
  };

  const onChange = (value: any, question: number, field: number) => {
    const newAnswers = [...answers];
    if (newAnswers?.[question]) {
      // @ts-ignore
      newAnswers[question][field] = value;
      setAnswers(newAnswers);
    }
    if (question !== 0) {
      const isFilled = checkFilled(answers[question]);
      setFilledStep(isFilled ? question : question - 1);
    }
    if (question === 0) {
      setUser(undefined);
    }
  };

  const login = async () => {
    setErrorMessage(undefined);
    if (answers[0]?.[0]) {
      const loginResult = await loginRequest(answers[0][0] as string, setLoading, geolocation?.region);

      if (loginResult) {
        setFilledStep(0);

        const processed = await processLogin({
          errors: {
            clinIdError,
            serverError,
            countryError,
            alreadyExistsError,
            redirectError,
          },
          loginValue: answers[0]?.[0] as string,
          loginResult,
          geolocation,
        });
        if (!processed.success) {
          setErrorMessage(processed.error || '');
          setLoading(false);
        } else {
          document.cookie = `userToken=${loginResult.token};Max-Age=300`;
          setUser(loginResult);
          setLoading(false);
          setStep(currentStep + 1);
          getUserData(loginResult);
        }
      } else {
        setErrorMessage(getError(serverError, clinIdError));
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const getUserData = async (loginResult?: LoginResultType) => {
    if (!createDataState && loginResult) {
      setCreateDataState('loading');

      const requestResult = await createDataRequest({
        owner: getOwner(owners, geolocation?.country),
        recordTypeName: getRecordType(recordTypes, geolocation?.country, geolocation?.region),
        countryCode: loginResult?.countryCode,
        region: loginResult?.region,
      });

      setCreateDataState(requestResult.success ? 'finished' : null);
    }
  };

  const submit = async () => {
    setErrorMessage(undefined);
    setLoading(true);
    const submitData = getSubmitData({
      questions,
      answers,
      createDataState,
      owner: getOwner(owners, geolocation?.country),
      recordTypeName: getRecordType(recordTypes, geolocation?.country, geolocation?.region),
      user,
    });

    if (createDataState !== 'finished') {
      await getUserData(user);
    }

    const result = await fetch('/api/survey', {
      method: 'POST',
      body: JSON.stringify(submitData),
    }).then(res => res.json());

    if (result.success) {
      let resultScreen = getResultScreen({
        negativeResult,
        neutralPoint,
        middleResult,
        positivePoint,
        positiveResult,
        resultPoint: submitData.result,
      });
      setResultScreen(resultScreen);
      setStep(currentStep + 1);
      setFilledStep(currentStep + 1);
      cleanSurveyCookies();
    } else {
      setErrorMessage(serverError.description || serverError.title || '');
    }
    setLoading(false);
  };

  useEffect(() => {
    if (document) {
      const elements = document.querySelectorAll(`.${classes.question}`);
      const elementsHeights = [...elements].map((element: Node) => (element as HTMLDivElement).offsetHeight);
      setHeights(elementsHeights);
      setCurrentHeight(elementsHeights[currentStep]);
    }
  }, [windowWidth]);

  useEffect(() => {
    if (document) {
      reloadCookies();
      const cookies = cookie.parse(document.cookie);
      if (cookies) {
        setGeolocation({
          country: cookies.country,
          countryLocale: cookies.countryLocale,
          locale: cookies.locale,
          region: cookies.region as keyof RegionsType,
          userLocale: cookies.userLocale,
        });
      }
    }
  }, []);

  return (
    <div
      className={cx(classes.application, className)}
      ref={applicationRef}
      {...others}
    >
      <Stepper length={(questions?.length || 0) + 1} currentStep={currentStep} filledStep={filledStep} />
      {currentStep === questions?.length ? (
        <Result {...(resultScreen || {})} >
          <Text component="h2" className={classes.title}>
            {`${stepText || ''} ${(questions?.length || 0) + 1}: ${resultsTitle}`}
          </Text>
        </Result>
      ) : (
        <QuestionWrapper
          setPreviousStep={() => setStep(currentStep - 1)}
          setNextStep={() => {
            if (currentStep === 0 && !user) {
              return login();
            }
            if (currentStep === (questions?.length || 0) - 1) {
              return submit();
            }
            return setStep(currentStep + 1);
          }}
          questionsLength={questions?.length || 0}
          filledStep={filledStep}
          nextButtonLabel={nextButtonLabel}
          previousButtonLabel={previousButtonLabel}
          subtitle={subtitle}
          currentStep={currentStep}
          bottomText={bottomText}
          disableNext={!checkFilled(answers[currentStep])}
          currentHeight={currentHeight}
          nextButtonTrackingKey={nextButtonTrackingKey}
          submitButtonTrackingKey={submitButtonTrackingKey}
          errorMessage={errorMessage}
          loading={loading}
          fullFieldsLength={
            (questions?.[currentStep].fields?.length || 0) + (questions?.[currentStep].secondaryFields?.length || 0)
          }
          fieldsAmountArray={answers.map(item => item.length)}
        >
          {questions?.map((question: QuestionProps, index: number) => (
            <Question
              key={index}
              {...question}
              stepText={stepText}
              questionIndex={index}
              onChange={onChange}
              answers={answers[index]}
              className={classes.question}
              currentHeight={currentHeight}
              onKeyDown={login}
              questionsLength={questions?.length || 0}
              toggleHeight={toggleQuestionHeight}
              loading={loading && currentStep === index}
            />
          ))}
        </QuestionWrapper>
      )}
    </div>
  );
}
