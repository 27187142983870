import React, { KeyboardEvent, useState } from 'react';
import cx from 'clsx';
import InputWrapper from '../InputWrapper/InputWrapper';
import classes from './TextInput.module.scss';

interface TextInputProps<T = {}> extends Omit<React.HTMLProps<HTMLDivElement>, 'onChange'> {
  error?: string;
  placeholder?: string;
  component?: React.ComponentType<T> | React.ElementType,
  disabled?: boolean;
  label?: string,
  sliderMax?: number,
  showError?: boolean,
  fullWidth?: boolean,
  halfWidth?: boolean,

  onKeyDown?(): void;

  onFocus?(): void;

  onBlur?(): void;

  onChange(value: string): void;
}

export default function TextInput<T = 'input'>({
  className,
  value,
  onChange,
  onFocus,
  onBlur,
  component = 'input',
  error,
  showError,
  placeholder = '',
  disabled = false,
  sliderMax,
  label = 'input_field',
  onKeyDown,
  fullWidth,
  halfWidth,
  ...others
}: TextInputProps<T>) {
  const isInput = component === 'input';
  const Element = component || 'input';
  const [focus, setFocus] = useState(false);

  const handleFocus = () => {
    setFocus(true);
    if (onFocus) {
      onFocus();
    }
  };

  const handleBlur = () => {
    setFocus(false);
    if (onBlur) {
      onBlur();
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && onKeyDown) {
      onKeyDown();
    }
  };

  return (
    <div className={cx(classes.textInput, className)} {...others}>
      <div className={cx(classes.textInner, {[classes.fullWidth]: fullWidth, [classes.halfWidth]: halfWidth} )}>
        {label && <label className={cx(classes.label, { [classes.error]: showError })} htmlFor={label}>{label}</label>}
        <InputWrapper
          error={error}
          showError={showError}
          withLabel={!!label}
          active={focus}
          noHover={Element === 'textarea'}
        >
          <Element
            className={cx(
              classes.input,
              {
                [classes.error]: showError,
                [classes.textarea]: Element === 'textarea',
              },
            )}
            error={showError}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            type="input"
            placeholder={placeholder}
            disabled={disabled}
            value={value}
            onChange={(event: any) => onChange(event.target.value)}
            aria-label={label || 'Input field'}
            {...others}
          />
        </InputWrapper>
      </div>
    </div>
  );
}
