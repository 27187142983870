import React from 'react';
import cx from 'clsx';
import SimpleButton from '../../SimpleButton/SimpleButton';
import ChevronIcon from '../../icons/Chevron';
import classes from './ArrowButton.module.scss';

export interface ArrowButtonProps extends React.HTMLProps<HTMLButtonElement> {
  direction?: 'top' | 'left' | 'bottom' | 'right'
}

export default function ArrowButton({
  className,
  direction,
  ...others
}: ArrowButtonProps) {
  return (
    <SimpleButton component="button" className={cx(classes.arrowButton, className)} {...others} type="button">
      <ChevronIcon direction={direction} size={25} />
    </SimpleButton>
  );
}
