import React, { useEffect, useRef, useState } from 'react';
import cx from 'clsx';
import Text from '../Text/Text';
import classes from './AnimatedTitle.module.scss';

export interface AnimatedTitleProps extends React.HTMLProps<HTMLDivElement> {
  text?: string,
  order?: number,
}

type titles = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export default function AnimatedTitle({
  className,
  text,
  order = 2,
  ...others
}: AnimatedTitleProps) {
  const [show, setShow] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (window && ref.current && !show) {
      const rect = ref.current.getBoundingClientRect();
      setShow(rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth));
    }
  };

  useEffect(() => {
    if (window) {
      handleScroll();
      window.addEventListener('resize', handleScroll);
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('resize', handleScroll);
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  if (!text) {
    return null;
  }
  return (
    <div className={cx(classes.animatedTitle, className)} ref={ref} {...others}>
      <Text component={`h${order}` as titles} className={cx(classes.idle, { [classes.show]: show })}>
        {text.split('').map((char, index) => (
          <span
            className={classes.char}
            key={index}
            style={{
              transitionDelay: `${index * 100}ms`,
            }}
          >
            {char}
          </span>
        ))}
      </Text>
      <Text component={`h${order}` as titles} className={cx(classes.hover, { [classes.show]: show })}>
        {text.split('').map((char, index) => (
          <span
            className={classes.char}
            key={index}
            style={{
              transitionDelay: index === 0 ? '50ms' : `${index * 100 + 50}ms`,
            }}
          >
            {char}
          </span>
        ))}
      </Text>
    </div>
  );
}
