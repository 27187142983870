import React from 'react';
import cx from 'clsx';
import { ButtonType } from '../../util/contentfulTypes';
import Column from './Column/Column';
import Container from '../Container/Container';
import classes from './BlockWithColumns.module.scss';

export interface BlockWithColumnsProps extends React.HTMLProps<HTMLDivElement> {
  theme?: 'dark' | 'light',
  columns?: {
    subtitle?: string,
    listColumns?: number,
    title?: string,
    description?: string,
    buttons?: ButtonType[],
    buttonsAlign?: 'center' | 'left' | 'right'
    width?: number,
  }[]
}

export default function BlockWithColumns({
  className,
  theme = 'light',
  columns = [],
  ...others
}: BlockWithColumnsProps) {
  return (
    <div className={cx(classes.blockContainer, { [classes.darkPadding]: theme === 'dark' }, className)} {...others}>
      {/*//@ts-ignore*/}
      <Container size="large" className={cx(classes.block, classes[theme])} {...others}>
        {columns?.map((column, index) => (Object.keys(column).length > 0 ? (
          <Column
            key={index}
            theme={theme}
            hideSubtitle={columns.filter(item => !item.subtitle).length === columns?.length}
            {...column}
          />
        ) : null))}
      </Container>
    </div>
  );
}
