import React, { KeyboardEvent, useEffect } from 'react';
import cx from 'clsx';
import { v4 } from 'uuid';
import { Range, getTrackBackground } from 'react-range';
import Text from '../Text/Text';
import colors from '../../styles/colors';
import classes from './Slider.module.scss';
import { IThumbProps, ITrackProps } from 'react-range/lib/types';

let timer: ReturnType<typeof setTimeout>;

interface SliderProps extends Omit<React.HTMLProps<HTMLDivElement>, 'onChange'> {
  value: number,
  minValue: number,
  maxValue: number,
  disabled: boolean,
  fullWidth?: boolean,
  halfWidth?: boolean,
  label?: string,

  onChange(value: number | Range): void,
}

export default function Slider({
  className,
  value = 0,
  onChange,
  minValue = 0,
  maxValue = 1000,
  disabled = false,
  fullWidth,
  halfWidth,
  label,
  ...others
}: SliderProps) {
  const handleTrackChange = (changeValue: number | Range) => {
    const element = document.querySelector('[data-tooltip]');
    if (element) {
      element.innerHTML = `${changeValue.toString()}`;
    }
    onChange(changeValue);
  };

  const removeActiveTrack = () => {
    const element = document.querySelector('[data-tooltip]');
    if (element) {
      if (element.classList.contains(classes.activeTrack)) {
        element.classList.remove(classes.activeTrack);
      }
    }
  };

  const handleTrackOnClick = () => {
    clearTimeout(timer);
    const element = document.querySelector('[data-tooltip]');
    if (element) {
      element.classList.add(classes.activeTrack);
      timer = setTimeout(() => removeActiveTrack(), 1000);
    }
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'ArrowLeft' && value !== 0) {
      handleTrackChange(value - 10);
    }
    if (e.key === 'ArrowRight' && value !== maxValue) {
      handleTrackChange(value + 10);
    }
  };

  useEffect(() => handleTrackOnClick(), [value]);

  const track = ({ props, children }: { props: ITrackProps, children: React.ReactNode }) => (
    <div
      {...props}
      className={classes.inputRangeTrack}
    >
      <span className={classes.notches}>
        {[...Array(11)].map((_, index) => (
          <span key={index} className={classes.notch}>
            {(index === 0 || index % 2 === 0) && (
              <Text key={index} className={classes.number}>
                {index === 10 ? `${maxValue}+` : index * 100}
              </Text>
            )}
          </span>
        ))}
      </span>
      <div
        ref={props.ref}
        className={classes.inputRangeActiveTrack}
        style={{
          background: getTrackBackground({
            values: [value],
            colors: [colors.blue, colors.lightGray],
            min: minValue,
            max: maxValue,
          }),
          alignSelf: 'center',
        }}
      />
      {children}
    </div>
  );

  const thumb = ({ props }: { props: IThumbProps }) => (
    <div
      {...props}
      className={classes.inputRangeSlider}
      aria-label="slider"
      tabIndex={0}
      onKeyDown={handleKeyDown}
    >
      <Text data-tooltip className={classes.tooltip}>{value}</Text>
    </div>
  );

  return (
    <div
      className={cx(classes.wrapper, className)}
      {...others}
    >
      <div
        className={classes.slider}
        id="slider"
        onKeyDown={handleKeyDown}
      >
        <Range
          values={[value]}
          max={maxValue}
          min={minValue}
          step={10}
          onChange={values => onChange(values[0])}
          renderTrack={track}
          renderThumb={thumb}
        />
      </div>
    </div>
  );
}
