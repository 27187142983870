import React from 'react';
import cx from 'clsx';
import { Check } from 'react-feather';
import InputWrapper from '../InputWrapper/InputWrapper';
import Text from '../../../Text/Text';
import classes from './Checkbox.module.scss';

export interface CheckboxProps extends Omit<React.HTMLProps<HTMLInputElement>, 'onChange'> {
  error?: string,
  label?: string,
  value: any,
  children?: React.ReactNode,
  trackingKey?: string,
  sliderMax?: number,
  showError?: boolean,
  checkboxInput?: boolean,
  component: React.ElementType,
  halfWidth?: boolean,
  fullWidth?: boolean,

  onFocus?(): void;

  onBlur?(): void;

  onChange(value: boolean): void;
}

export default function Checkbox({
  className,
  value,
  onChange,
  onFocus,
  onBlur,
  disabled = false,
  error,
  component = 'div',
  label = '',
  children = null,
  sliderMax,
  trackingKey,
  showError,
  checkboxInput,
  halfWidth,
  fullWidth,
  ...others
}: CheckboxProps) {
  const Element = component || 'div';

  const elementProps = {
    onClick: () => onChange(!value),
    type: 'button',
    'aria-label': label,
    ...(trackingKey ? { ['data-tracking-key']: trackingKey } : {}),
  };

  return (
    <InputWrapper
      error={error}
      showError={showError}
      className={cx(classes.checkboxWrapper, className, { [classes.checked]: value, [classes.halfWidth]: halfWidth })}
      active={value}
      checkboxInput={checkboxInput}
      withoutError
      component={component === 'div' ? 'button' : 'div'}
      disabled={disabled}
      onFocus={onFocus}
      onBlur={onBlur}
      {...(component === 'div' ? elementProps : {})}
      {...others}
    >
      <Element className={classes.button} {...(component === 'button' ? elementProps : {})}>
        <div
          className={cx(classes.checkbox, { [classes.checkboxError]: showError })}
          role="checkbox"
          aria-checked={value}
          aria-label={label}
        >
          <Check className={classes.checkIcon} size={16} />
        </div>
        <Text
          component="label"
          className={cx(classes.label, {
            [classes.checkedLabel]: value,
            [classes.error]: showError,
          })}
        >
          {label}
        </Text>
      </Element>
      {children}
    </InputWrapper>
  );
}
