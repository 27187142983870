import React, { KeyboardEvent, useEffect, useState } from 'react';
import cx from 'clsx';
import { Info } from 'react-feather';
import ButtonsContainer from '../ButtonsContainer/ButtonsContainer';
import Button from '../Button/Button';
import Text from '../Text/Text';
import Container from '../Container/Container';
import classes from './QuestionWrapper.module.scss';

export interface QuestionWrapperProps extends React.HTMLProps<HTMLDivElement> {
  nextButtonLabel?: string,
  previousButtonLabel?: string,
  questionsLength?: number,
  subtitle?: string,
  currentStep: number,
  filledStep: number,
  bottomText?: string | null,
  currentHeight?: number,
  nextButtonTrackingKey?: string,
  submitButtonTrackingKey?: string,
  disableNext?: boolean,
  errorMessage?: string,
  loading?: boolean,
  fullFieldsLength: number,
  fieldsAmountArray: number[],

  setPreviousStep(): void,

  setNextStep(): void,
}

export default function QuestionWrapper({
  nextButtonLabel,
  previousButtonLabel,
  questionsLength = 0,
  subtitle,
  currentStep,
  filledStep,
  setPreviousStep,
  setNextStep,
  bottomText,
  children,
  currentHeight,
  nextButtonTrackingKey,
  submitButtonTrackingKey,
  disableNext,
  errorMessage,
  loading,
  fullFieldsLength,
  fieldsAmountArray,
  ...others
}: QuestionWrapperProps) {
  const [previousButton, setPreviousButton] = useState<HTMLButtonElement | null>(null);
  const [nextButton, setNextButton] = useState<HTMLButtonElement | null>(null);
  const [firstFieldIndex, setFirstFieldIndex] = useState(0);
  const [secondRender, setSecondRender] = useState(false);

  const selectFirstField = async () => {
    if (document) {
      const question = document.querySelectorAll('[data-label="question"]')[currentStep];
      setTimeout(() => {
        ((question.querySelector('[data-index="0"]') as HTMLElement)?.querySelector('input, button, [aria-label="slider"]') as HTMLElement)?.focus();
      }, 210);
    }
  };

  useEffect(() => {
    if (document) {
      setNextButton(document.querySelector('#next-button') as HTMLButtonElement || null);
    }
    setSecondRender(true);
  }, []);

  useEffect(() => {
    if (errorMessage) {
      selectFirstField();
    }
  }, [errorMessage]);

  useEffect(() => {
    if (!previousButton && document) {
      setPreviousButton(document.querySelector('#previous-button') as HTMLButtonElement || null);
    }
    if (secondRender) {
      selectFirstField();
    }
  }, [currentStep]);

  if (questionsLength === 0) {
    return null;
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Tab') {
      //@ts-ignore
      const index = (e.target as HTMLElement)
        .closest('[data-label="applicationField"]').getAttribute('data-index');

      if (parseInt(index || '0') === fullFieldsLength - 1) {
        e.preventDefault();
        if (currentStep !== 0 && previousButton) {
          previousButton?.focus();
        } else {
          nextButton?.focus();
        }
      }
    }
  };

  return (
    <Container size="large" {...others}>
      {subtitle && (
        <div className={classes.textWrapper}>
          <Text className={classes.subtitle}>{subtitle}</Text>
        </div>
      )}
      <div className={cx(classes.errorWrapper, { [classes.showError]: errorMessage })}>
        {errorMessage && <Text className={classes.error}>{errorMessage}</Text>}
      </div>
      <div
        className={classes.currentQuestion}
        style={{ height: currentHeight }}
      >
        <div
          className={classes.allQuestions}
          onKeyDown={handleKeyDown}
          style={{
            width: `${questionsLength}00%`,
            transform: `translateX(-${(100 / questionsLength) * currentStep}%)`,
          }}
        >
          {children}
        </div>
      </div>
      <ButtonsContainer align="center">
        {currentStep !== 0 && (
          <Button
            id="previous-button"
            component="button"
            onClick={() => {
              setFirstFieldIndex(firstFieldIndex - fieldsAmountArray[currentStep]);
              setPreviousStep();
            }}
            ariaLabel={previousButtonLabel}
            disabled={loading || currentStep === 0}
            secondary
            tabIndex={0}
          >
            {previousButtonLabel}
          </Button>
        )}
        <Button
          component="button"
          id="next-button"
          onClick={() => {
            setFirstFieldIndex(firstFieldIndex + fieldsAmountArray[currentStep]);
            setNextStep();
          }}
          ariaLabel={nextButtonLabel}
          disabled={loading || (currentStep !== 0 ? (disableNext || currentStep === questionsLength) : disableNext)}
          trackingKey={currentStep === questionsLength - 1 ?
            submitButtonTrackingKey?.replace('%step%', (currentStep + 1).toString()) :
            nextButtonTrackingKey?.replace('%step%', (currentStep + 1).toString())}
          tabIndex={0}
        >
          {nextButtonLabel}
        </Button>
      </ButtonsContainer>
      <div className={classes.textWrapper}>
        <div className={cx(classes.bottomText, { [classes.showText]: bottomText && bottomText.length > 0 })}>
          <div className={classes.info}>
            <Info size={30} />
          </div>
          <Text>{bottomText}</Text>
        </div>
      </div>
    </Container>
  );
}
