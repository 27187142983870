import React from 'react';
import cx from 'clsx';
import { Info } from 'react-feather';
import Stepper from '../Stepper/Stepper';
import ButtonsContainer from '../ButtonsContainer/ButtonsContainer';
import Button from '../Button/Button';
import Text from '../Text/Text';
import Container from '../Container/Container';
import { BlockType } from '../../util/contentfulTypes';
import TextBlock from '../TextBlock/TextBlock';
import contentMapper from '../Page/contentMapper';
import classes from './Result.module.scss';

export interface ResultProps extends React.HTMLProps<HTMLDivElement> {
  key?: string,
  title?: string,
  description?: string,
  adElements?: BlockType[]
}

export default function Result({
  className,
  title,
  description,
  adElements,
  children,
  ...others
}: ResultProps) {
  return (
    <Container className={cx(classes.result, className)} size="large" {...others}>
      {children}
      <TextBlock title={title} description={description} className={classes.title} />
      {adElements?.map(item => {
        if (!item.contentTypeId) {
          return null;
        }
        //@ts-ignore
        const Element = contentMapper[item.contentTypeId];
        if (Element) {
          return <Element className={classes.block} {...item} />;
        }
        return null;
      })}
    </Container>
  );
}
