import { Asset, Entry } from 'contentful';
import {
  AccordionType,
  AnimatedCard,
  ApplicationType,
  BlockType,
  BlockWithColumnsType,
  ButtonType,
  CardCarouselType,
  CardType,
  ColumnType,
  FieldType,
  GetStartedBlockType,
  IconsListType,
  LogoGalleryType, OwnerType,
  QuestionType, RecordType, ResultType,
  ScrollerType,
  TextType,
  TextWithIcon,
} from '../../util/contentfulTypes';
import IconsList from '../IconsList/IconsList';
import Scroller from '../Scroller/Scroller';
import AccordionBlock from '../AccordionBlock/AccordionBlock';
import Block from '../Block/Block';
import BlockWithColumns from '../BlockWithColumns/BlockWithColumns';
import CardCarousel from '../CardCarousel/CardCarousel';
import LogoGallery from '../LogoGallery/LogoGallery';
import TextBlock from '../TextBlock/TextBlock';
import Application from '../Application/Application';

const IconsListParser = ({ elements, ...rest }: IconsListType) => {
  return (
    <IconsList
      {...rest}
      elements={elements?.map((element: Entry<TextWithIcon>) => ({
        ...element.fields,
        image: element.fields.image?.fields?.file?.url,
        imageAlt: element.fields.image?.fields.description,
      }))}
    />
  );
};

const ScrollerParser = ({ cards, ...rest }: ScrollerType) => (
  <Scroller
    {...rest}
    cards={cards?.map((card: Entry<CardType>) => ({
      ...card.fields,
      image: card.fields.image?.fields?.file?.url,
      imageAlt: card.fields.image?.fields.description,
      button: card.fields.button?.fields,
    }))}
  />
);

const AccordionBlockParser = ({ image, accordionElements, ...rest }: AccordionType) => (
  <AccordionBlock
    {...rest}
    image={image?.fields?.file?.url}
    imageAlt={image?.fields.description}
    accordionElements={accordionElements?.map((element: Entry<TextType>) => element.fields)}
  />
);


const GetStartedBlockParser = ({ content, buttons, ...rest }: GetStartedBlockType) => (
  <Block
    {...rest}
    title={content?.fields.title}
    description={content?.fields.description}
    buttons={buttons?.map((button: Entry<ButtonType>) => button.fields)}
  />
);

const BlockParser = ({ image, buttons, cards, ...rest }: BlockType) => (
  <Block
    {...rest}
    image={image?.fields?.file?.url}
    imageAlt={image?.fields?.description}
    buttons={buttons?.map((button: Entry<ButtonType>) => button.fields)}
    cards={cards?.map((card: Entry<AnimatedCard>) => card.fields)}
  />
);

const BlockWithColumnsParser = ({ columns, ...rest }: BlockWithColumnsType) => (
  <BlockWithColumns
    {...rest}
    columns={columns?.map((column: Entry<ColumnType>) => ({
      ...column.fields,
      buttons: column.fields.buttons?.map((button: Entry<ButtonType>) => button.fields) || [],
    }))}
  />
);

const CardCarouselParser = ({ cards, ...rest }: CardCarouselType) => (
  <CardCarousel
    {...rest}
    cards={cards?.map((card: Entry<CardType>) => ({
      ...card.fields,
      image: card.fields.image?.fields?.file?.url || undefined,
      imageAlt: card.fields.image?.fields.description || undefined,
      button: card.fields.button?.fields,
    }))}
  />
);

const LogoGalleryParser = ({ images, ...rest }: LogoGalleryType) => (
  <LogoGallery
    {...rest}
    images={images?.map((image: Asset) => ({
      src: image?.fields?.file?.url,
      alt: image?.fields.description,
    }))}
  />
);

const mapResult = (result?: Entry<ResultType>) => (result ? ({
  ...result?.fields,
  adElements: result?.fields?.adElements?.map((el: Entry<BlockType>) => (
    { ...el.fields, contentTypeId: el.sys.contentType.sys.id }
  )),
}) : {});

const ApplicationParser = ({
  middleResult,
  negativeResult,
  positiveResult,
  questions,
  owners,
  recordTypes,
  alreadyExistsError,
  clinIdError,
  countryError,
  serverError,
  redirectError,
  ...rest
}: ApplicationType) => (
  <Application
    {...rest}
    alreadyExistsError={alreadyExistsError?.fields}
    clinIdError={clinIdError?.fields}
    countryError={countryError?.fields}
    serverError={serverError.fields}
    redirectError={redirectError?.fields}
    owners={owners.fields.owners?.map((owner: Entry<OwnerType>) => ({ ...owner.fields })) || []}
    recordTypes={{
      countries: recordTypes.fields.countries?.map((type: Entry<RecordType>) => type.fields),
      regions: recordTypes.fields.regions?.map((type: Entry<RecordType>) => type.fields),
      default: recordTypes.fields.default.fields,
    }}
    middleResult={mapResult(middleResult)}
    negativeResult={mapResult(negativeResult)}
    positiveResult={mapResult(positiveResult)}
    questions={questions?.map((question: Entry<QuestionType>) => ({
      ...question.fields,
      fields: question.fields?.fields?.map((field: Entry<FieldType>) => field.fields),
      secondaryFields: question.fields?.secondaryFields?.map((field: Entry<FieldType>) => field.fields),
    }))}
  />
);

const map = {
  accordion: AccordionBlockParser,
  iconsList: IconsListParser,
  scroller: ScrollerParser,
  block: BlockParser,
  blockWithColumns: BlockWithColumnsParser,
  cardCarousel: CardCarouselParser,
  logoGallery: LogoGalleryParser,
  application: ApplicationParser,
  getStartedBlock: GetStartedBlockParser,
  componentText: TextBlock,
};

export default map;
