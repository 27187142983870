import React from 'react';
import cx from 'clsx';
import { Check } from 'react-feather';
import classes from './Stepper.module.scss';

interface StepperProps extends React.HTMLProps<HTMLDivElement> {
  length?: number,
  currentStep?: number,
  filledStep?: number,
  style?: Record<string, any>,
}

export default function Stepper({
  className,
  length = 0,
  currentStep = 0,
  filledStep = -1,
  style = {},
  ...others
}: StepperProps) {
  return (
    <div
      className={cx(classes.stepper, className)}
      style={{ ...style }}
      {...others}
    >
      <div className={classes.steps}>
        {[...Array(length)].map((step, index) => (
          <div className={classes.step} key={index}>
            <div className={classes.circle}>
              <div className={cx(classes.blueCircle, { [classes.activeCircle]: currentStep >= index })}>
                <Check size={20} className={cx(classes.check, { [classes.showCheck]: filledStep >= index })} />
              </div>
            </div>
            {index !== length - 1 && (
              <div className={classes.connection}>
                <div
                  className={cx(classes.blueConnection, { [classes.showBlueConnection]: currentStep - 1 >= index })}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
