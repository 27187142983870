import React, { useEffect, useRef, useState } from 'react';
import cx from 'clsx';
import Text from '../../Text/Text';
import { X } from 'react-feather';
import SimpleButton from '../../SimpleButton/SimpleButton';
import classes from './Spoiler.module.scss';

export interface BlockProps extends React.HTMLProps<HTMLDivElement> {
  title?: string,
  description?: string,
}

export default function Spoiler({
  className,
  title,
  description,
  ...others
}: BlockProps) {
  const [show, setShow] = useState(false);
  const [scrollHeight, setScrollHeight] = useState(0);
  const dropdownRef = useRef<HTMLDivElement>(null);


  function onToggle() {
    if (dropdownRef.current) {
      if (dropdownRef.current?.style.height === '0px') {
        dropdownRef.current.style.height = `${scrollHeight}px`;
      } else {
        dropdownRef.current.style.height = `${scrollHeight}px`;
        window.getComputedStyle(dropdownRef.current, null).getPropertyValue('height');
        dropdownRef.current.style.height = '0px';
      }
    }
  }

  function onTransitionEnd() {
    if (dropdownRef.current) {
      if (dropdownRef.current.style.height !== '0px') {
        dropdownRef.current.style.height = 'auto';
      }
    }
  }

  //eslint-disable-next-line
  useEffect(() => {
    if (dropdownRef.current) {
      if (scrollHeight === 0) {
        setScrollHeight(dropdownRef.current.scrollHeight);
      }
      onToggle();
      dropdownRef.current.addEventListener('transitionend', onTransitionEnd);
      return () => {
        if (dropdownRef.current) {
          dropdownRef.current.removeEventListener('transitionend', onTransitionEnd);
        }
      };
    }
  }, [show]);


  return (
    <div className={cx(classes.spoiler, { [classes.show]: show }, className)} {...others}>
      <SimpleButton className={classes.titleWrapper} component="button" onClick={() => setShow(!show)}>
        <Text component="h3" className={classes.title}>{title}</Text>
        <X size={24} className={classes.closeIcon} />
      </SimpleButton>
      <div className={classes.descriptionWrapper} ref={dropdownRef}>
        <Text className={classes.description}>{description}</Text>
        <div className={classes.padding}/>
      </div>
    </div>
  );
}
