import React from 'react';
import cx from 'clsx';
import Text from '../Text/Text';
import ImageBlockWrapper from '../ImageBlockWrapper/ImageBlockWrapper';
import Spoiler from './Spoiler/Spoiler';
import classes from './AccordionBlock.module.scss';

export interface AccordionProps extends React.HTMLProps<HTMLDivElement> {
  image?: string,
  imageAlt?: string,
  imageRight?: boolean,
  title?: string,
  accordionElements?: {
    title?: string,
    description?: string,
  }[]
  withVideo?: boolean,
  video?: string,
}

export default function AccordionBlock({
  className,
  image,
  imageAlt,
  imageRight,
  title,
  accordionElements = [],
  withVideo,
  video,
  ...others
}: AccordionProps) {
  return (
    <ImageBlockWrapper
      className={cx(classes.block, className)}
      image={image}
      imageAlt={imageAlt}
      imageRight={imageRight}
      video={video}
      withVideo={withVideo}
      theme="light"
      {...others}
    >
      <div className={classes.content}>
        {title && <Text component="h2" className={classes.title}>{title}</Text>}
        <div className={classes.accordion}>
          {accordionElements?.map((item, index) => (
            <Spoiler {...item} key={index} />
          ))}
        </div>
      </div>
    </ImageBlockWrapper>
  );
}
