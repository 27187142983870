import type { QuestionProps } from '../../Question/Question';
import { LoginResultType, OwnerType, RegionsType } from '../../../util/types';

interface SubmitDataType {
  questions?: QuestionProps[],
  answers: ((string | number | boolean)[] | undefined)[],
  createDataState: 'loading' | 'finished' | null,
  owner?: OwnerType,
  recordTypeName?: string,
  user?: LoginResultType
}

const getAnswer = (question: QuestionProps, index: number, isMain: boolean, answer?: string | number | boolean) => {
  const field = isMain ? question?.fields?.[index] : question.secondaryFields?.[index];
  if (!field) {
    return '';
  }
  switch (typeof answer) {
    case 'string':
      return answer;
    case 'number':
      return answer.toString();
    case 'boolean':
      return answer ? field.label : '';
    default:
      return '';
  }
};

export default function getSubmitData({
  questions,
  answers,
  owner,
  recordTypeName,
  user,
}: SubmitDataType) {
  const data = questions?.map((question, index: number) => {
    return {
      mainAnswers: answers[index]?.slice(0, (question.fields?.length || 0))
        .map((item, j) => getAnswer(question, j, true, item))
        .filter(item => item && item !== '')
        .join(', '),
      secondaryAnswers: answers[index]?.slice((question.fields?.length || 0), answers[index]?.length)
        .map((item, j) => getAnswer(question, j, false, item))
        .filter(item => item && item !== '')
        .join(', '),
      salesforceKey: question.salesforceKey,
      secondarySalesforceKey: question.secondarySalesforceKey,
    };
  });

  return {
    data,
    owner,
    recordTypeName,
    user,
    result: 70
  };
}
