import BugsnagClient from '../../../util/bugsnag';

export default async function login(clinId: string, setLoading: (state: boolean) => void, region?: string | null) {
  setLoading(true);
  // setTimeout(() => {
  //   setLoading(false);
  //   setFilledStep(0);
  //   setStep(currentStep + 1)
  // }, 3000)
  const connection = await fetch('api/connect', {
    method: 'POST',
    body: JSON.stringify({
      first: true,
      region,
    }),
  })
    .then(response => response.json())
    .catch((err) => {
      BugsnagClient?.notify(err);
      return null;
    });

  if (!connection?.success) {
    // setClinIdError(main.error_text);
    setLoading(false);
    return;
  } else {
    document.cookie = `accessToken=${connection.token};Max-Age=300`;
  }

  return await fetch('/api/user', {
    method: 'POST',
    body: JSON.stringify({
      clinId,
    }),
  })
    .then(response => response.json())
    .catch((err) => {
      BugsnagClient?.notify(err);
      return null;
    });
}
