import React, { useEffect, useRef, useState } from 'react';
import cx from 'clsx';
import breakpoints from '../../styles/breakpoints';
import TopBorderCard, { TopBorderCardProps } from './TopBorderCard/TopBorderCard';
import useWindowWidth from '../../hooks/useWindowWidth';
import classes from './TopBorderCardContainer.module.scss';

export interface TopBorderCardContainerProps extends Omit<React.HTMLProps<HTMLDivElement>, 'onScroll'> {
  cards?: TopBorderCardProps[],
  cardWidth?: number,
  style?: Record<string, any>,
  absolute?: boolean,
  scrolledWidth?: number

  updateCardsWidth(width: number): void;

  updateActiveCard?(index: number): void;

  updateScrollWidth?(width: number): void;
}

export default function TopBorderCardContainer({
  className,
  cards = [],
  cardWidth,
  style = {},
  absolute = false,
  scrolledWidth = 0,
  updateCardsWidth,
  updateActiveCard,
  updateScrollWidth,
  ...others
}: TopBorderCardContainerProps) {
  const [height, setHeight] = useState(200);
  const innerRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const windowWidth = useWindowWidth(1050);
  const [scrollWidth, setScrollWidth] = useState(0);

  const handleScroll = (e: Event) => setScrollWidth((e.target as HTMLDivElement).scrollLeft);

  useEffect(() => {
    if (updateScrollWidth) {
      const delayDebounceFn = setTimeout(() => {
        updateScrollWidth(scrollWidth);
      }, 200);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [scrollWidth])

  useEffect(() => {
    if (innerRef.current) {
      updateCardsWidth(innerRef.current.offsetWidth);
      setHeight(innerRef.current.offsetHeight);
      setScrollWidth(0)
    }
  }, [windowWidth]);

  useEffect(() => {
    if (innerRef.current) {
      updateCardsWidth(innerRef.current.offsetWidth);
      if (updateScrollWidth) {
        innerRef.current.addEventListener('scroll', handleScroll);

        return () => {
          innerRef.current?.removeEventListener('scroll', handleScroll);
        };
      }
    }
  }, [innerRef]);

  useEffect(() => {
    if (innerRef.current) {
      setHeight(innerRef.current.offsetHeight);
    }
  }, [cardWidth]);

  useEffect(() => {
    if (absolute && innerRef.current) {
      innerRef.current.scrollTo({ left: scrolledWidth, top: 0, behavior: 'smooth' });
    }
  }, [scrolledWidth]);

  if (!cards || cards.length === 0) {
    return null;
  }

  return (
    <div
      className={cx(classes.topBorderCardContainer, className)}
      {...others}
      style={{ ...style, height }}
      ref={containerRef}
    >
      <div
        className={cx(classes.innerContainer, { [classes.absolute]: absolute })}
        ref={innerRef}
      >
        {cards.map((item, index) => (
          <TopBorderCard
            key={index}
            index={0}
            className={classes.card}
            {...item}
            width={cardWidth}
          />
        ))}
      </div>
    </div>
  );
}
